import * as React from "react"
import Stack from "@mui/material/Stack"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Header from "../../components/header"
import ImageWavesLight from "../../images/bg-waves-light.png"
import BlogPreview from "../../components/blogPreview"

import Blog1 from "../../images/blog-1.png"
import Blog2 from "../../images/blog-2.jpg"
import Blog3 from "../../images/blog-3.jpg"

const IndexBlog = () => (
  <Layout>
    <Seo title="Start EN" description="Start EN" keywords="Start" />
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      style={{
        background: `rgba(0,0,0,.85) url(${ImageWavesLight}) no-repeat fixed left center`,
        backgroundSize: "cover",
        overflowX: "hidden",
      }}
    >
      <Header siteName="blog" rootPath="/" />
      <Container maxWidth="xl">
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          marginTop={10}
          marginBottom={10}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {/* <Link to="/de/">DE</Link> */}
            <Typography variant="h1" fontSize="5rem">
              BLOGGING
            </Typography>
            <Typography variant="body" fontSize="2rem" textAlign="center">
              All about Live-Streaming, datarhei applications, use cases and
              solutions.
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
          marginBottom={11}
        >
          <BlogPreview
            titel="Resteamer in action"
            description="We have the tools to help you develop, deploy and manage any video project at any stage."
            image_src={Blog1}
            image_alt="Blog Service Release"
            link="/blog/for-gamer/"
          />
          <BlogPreview
            titel="Resteamer v2.1.0"
            description="We have the tools to help you develop, deploy and manage any video project at any stage."
            image_src={Blog2}
            image_alt="Blog Service Release"
            link="/blog/for-gamer/"
          />
          <BlogPreview
            titel="Resteamer v2.0.0"
            description="We have the tools to help you develop, deploy and manage any video project at any stage."
            image_src={Blog3}
            image_alt="Blog Service Release"
            link="/blog/for-gamer/"
          />
        </Stack>
      </Container>
    </Stack>
  </Layout>
)

export default IndexBlog
