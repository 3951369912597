import * as React from "react"
import makeStyles from "@mui/styles/makeStyles"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  Titel: {
    color: theme.palette.service.main,
    fontSize: "1.6em",
    fontWeight: "600",
  },
  Content: {
    fontSize: "1.05em",
  },
  Button: {
    color: "#000",
    fontSize: "1rem",
    fontWeight: 600,
    background: "rgb(234,234,5)",
    height: "1.9em",
    marginTop: "1em",
  },
  Image: {
    display: "block",
    "-webkit-background-size": "cover",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    "-webkit-background-position": "center",
    backgroundPosition: "center",
    width: "100%",
    objectFit: "cover",
    objectPosition: "left",
    background: "#000000",
    "-webkit-mask-image":
      "linear-gradient(to right, rgba(0, 0, 0, 1.0) 90%, rgba(0, 0, 0, .2) 100%)",
    maskImage:
      "linear-gradient(to right, rgba(0, 0, 0, 1.0) 90%, rgba(0, 0, 0, .2) 100%)",
  },
}))

const Component = function (props) {
  const classes = useStyles()
  return (
    <Card
      sx={{
        display: "flex",
        padding: 0,
        backgroundColor: "#333",
        borderRadius: 3,
        border: `1px rgba(255,255,255,.15) solid`,
      }}
      variant="outlined"
    >
      <CardMedia
        component="img"
        sx={{
          width: 125,
          objectPosition: "left",
          background: "#000000",
          maskImage:
            "linear-gradient(to right, rgba(0, 0, 0, 1.0) 90%, rgba(0, 0, 0, .2) 100%)",
        }}
        image={props.image_src}
        alt={props.image_alt}
      />
      {/* <GatsbyImage
                className={classes.Image}
                layout="fullWidth"
                image={props.image_src}
                width={300}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt={props.image_alt}
            /> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          paddingLeft: 0.5,
        }}
      >
        <CardContent sx={{ flex: "1 0 auto", marginBottom: -1 }}>
          <Typography component="div" variant="h5" className={classes.Titel}>
            {props.titel}
          </Typography>
          <Typography
            variant="body"
            component="div"
            className={classes.Content}
          >
            {props.description}
          </Typography>
          <Link
            style={{
              fontSize: "1.2rem",
              fontWeight: 500,
              color: "#fff",
              textDecoration: "none",
            }}
            to={props.link}
          >
            <Button variant="contained" size="small" className={classes.Button}>
              Read
            </Button>
          </Link>
        </CardContent>
      </Box>
    </Card>
  )
}
export default Component

Component.defaultProps = {
  titel: "",
  description: "",
  image_src: "",
  image_alt: "",
  link: "",
}
